<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col cols="12" md="12">
                        <b-card no-body>
                            <b-card-header>
                                <h4 class="card-title">{{ i18nT(`Candidate`) }}</h4>
                                <b-dropdown
                                    no-caret
                                    right
                                    toggle-class="p-0"
                                    variant="link"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            class="align-middle text-body"
                                            icon="MoreVerticalIcon"
                                            size="16"
                                        />
                                    </template>
                                    <b-dropdown-item @click="onCopy()">
                                        <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="onSave()">
                                        <span class="align-middle ml-50">{{ i18nT(`Save`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="allCandidateData.PublicationStatus !== 'ACTIVE'"
                                                     @click="sendInvite()"
                                    >
                                        <span v-if="allCandidateData.Invited === 1"
                                              class="align-middle ml-50">{{
                                                i18nT(`Resend Invitation`)
                                            }}</span>
                                        <span v-else class="align-middle ml-50">{{ i18nT(`Send Invitation`) }}</span>
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="hasRouteId" @click="onDelete()">
                                        <span class="align-middle ml-50" style="color: red">{{ i18nT(`Delete`) }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-card-header>
                            <b-card-body>
                                <b-row class="border-bottom mb-1">
                                    <b-col cols="3">
                                        <h5 class="mt-2">
                                            {{ i18nT(`Profile image`) }}
                                        </h5>
                                    </b-col>
                                    <b-col md="9">
                                        <avatar-control
                                            :profile-data="allCandidateData"
                                            @setNewAvatar="onSetNewAvatar"

                                        />
                                    </b-col>
                                </b-row>

                                <!-- Personal DETAILS -->
                                <b-row class="border-bottom">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{ i18nT(`Personal details`) }}
                                        </h5>
                                    </b-col>


                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <!-- Gender -->
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <div class="demo-inline-spacing pl-1 mb-2">
                                                    <b-form-radio
                                                        v-model="gender"
                                                        name="some-radios3"
                                                        value="female"
                                                    >
                                                        {{ i18nT(`Female`) }}
                                                    </b-form-radio>
                                                    <b-form-radio
                                                        v-model="gender"
                                                        name="some-radios3"
                                                        value="male"
                                                    >
                                                        {{ i18nT(`Male`) }}
                                                    </b-form-radio>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <!-- Main Data -->
                                        <b-row>
                                            <!-- First / Last name -->
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`First name`)"
                                                    class="required"
                                                    label-for="firstName"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`First name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="allCandidateData.FirstName"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Last name`)"
                                                    class="required"
                                                    label-for="lastName"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Last name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            id="title"
                                                            v-model="allCandidateData.LastName"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <!-- Email / PHONE -->
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`E-mail`)"
                                                    class="required"
                                                    label-for="email"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`E-mail`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="email"
                                                            :disabled="disabled"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                            <b-form-group
                                                    :label="i18nT(`Phone`)"
                                                    label-for="title"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Phone`)"
                                                        :rules="{ validPhoneNumber: mazphoneObj }"
                                                    >
                                                        <MazPhoneNumberInput
                                                            :key="phoneReady"
                                                            id="Phone"
                                                            default-country-code="FR"
                                                            v-model="phones"
                                                            @update="updatePhoneObj"
                                                            :translations="{
                                      countrySelectorLabel: i18nT(`Country code`),
                                      countrySelectorError: i18nT(`Select a country`),
                                      phoneNumberLabel: i18nT(`Phone number`),
                                      example: i18nT(`Example:`),
                                    }"
                                                            :disabled="!iCan('contact_data', 'write')"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <!-- DOB -->
                                            <b-col
                                                cols="12"
                                                md="4"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="i18nT(`First name`)"
                                                    rules="date_format"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Date of birth`)"
                                                    >
                                                        <date-picker
                                                            v-model="allCandidateData.BirthDate"
                                                            reset-button
                                                            :manual-input="true"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>


                                            <!-- Nationality -->
                                            <b-col
                                                cols="12"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Nationality`)"
                                                    label-for="firstName"
                                                >
                                                    <b-form-input
                                                        v-model="allCandidateData.Nationality"
                                                        type="text"
                                                    />

                                                </b-form-group>
                                            </b-col>

                                            <!-- Social security -->
                                            <b-col
                                                cols="12"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Social security`)"
                                                    label-for="lastName"
                                                >

                                                    <b-form-input
                                                        id="social"
                                                        v-model="allCandidateData.SocialSecurity"
                                                    />

                                                </b-form-group>
                                            </b-col>


                                        </b-row>

                                        <!-- Send Invite -->
                                        <b-row v-if="!allCandidateData.Id">
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <p>
                                                    {{
                                                        i18nT(`You can notify and invite the person to join skileo and create his account. Once he creates his profile, he will be able to access, complete and rectify any personal information.`)
                                                    }}</p>
                                                <b-form-checkbox
                                                    v-model="send_invite"
                                                    :checked="send_invite"
                                                    name="check-button"
                                                    switch
                                                >
                                                    {{
                                                        i18nT(`Send an invitation to join the software and confirm the account.`)
                                                    }}
                                                </b-form-checkbox>
                                                <b-alert
                                                    class="mt-1"
                                                    show
                                                    variant="primary"
                                                >
                                                    <div class="alert-body">
                        <span>
                          <b-badge variant="primary">
                            {{ i18nT(`Info`) }}
                          </b-badge>
                          {{
                                i18nT(`Once the user has accepted the invitation and completed his own profile, you won't be able to edit his personal information. He will be the only one able to edit any personal data.`)
                            }}
                        </span>
                                                    </div>
                                                </b-alert>
                                            </b-col>

                                        </b-row>

                                    </b-col>
                                </b-row>
                                <b-row class="border-bottom">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{ i18nT(`Additional information`) }}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        class="pt-2"
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Job title`)"
                                                    class="required"
                                                    label-for="jobTitle"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Job Title`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="position"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Presentation`)"
                                                    label-for="description"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-default"
                                                        v-model="presentation"
                                                        :placeholder="i18nT(`Brief description`)"
                                                        rows="3"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Skills`)"
                                                >
                                                    <v-select
                                                        v-model="skills"
                                                        :options="skillOptions"
                                                        :placeholder="i18nT(`Select or create skills`)"
                                                        label="text"
                                                        multiple
                                                        push-tags
                                                        taggable
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="pt-2 pb-2 border-bottom">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{ i18nT(`Documents`) }}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <!-- CV SELECTOR -->
                                        <div>
                                            <span>
                                            <feather-icon icon="PaperclipIcon" size="16" style="margin-right: 4px;"/>
                                            CV
                                          </span>
                                            <br>
                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                class="mt-0 mt-1 mr-1 mb-2"
                                                variant="outline-primary"
                                                @click="onFilePick('cv')"
                                            >
                                                <feather-icon icon="PlusCircleIcon"/>
                                                {{ i18nT(`Add file`) }}
                                            </b-button>

                                            <b-form-file
                                                ref="cvfilePicker"
                                                v-model="apply_cv"
                                                class="hidden"
                                            />
                                            <span v-if="apply_cv || apply_cv_name">
                                                <b-link v-if="apply_cv_url" :href="apply_cv_url">
                                                    {{ apply_cv_name }}
                                                </b-link>
                                                <span v-else> 
                                                    {{ apply_cv_name }}
                                                </span>
                                                <feather-icon icon="Trash2Icon"
                                                              size="16" style="margin-right: 8px;"
                                                              class="cursor-pointer"
                                                              @click="onFileDelete('cv')"/>
                                            </span>
                                        </div>

                                        <!-- Cover letter SELECTOR -->
                                        <div>
                                          <span>
                                            <feather-icon icon="PaperclipIcon" size="16" style="margin-right: 4px;"/>
                                            {{ i18nT(`Motivation letter`) }}
                                          </span><br>
                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                class="mt-0 mt-1 mr-1 mb-2"
                                                variant="outline-primary"
                                                @click="onFilePick('letter')"
                                            >
                                                <feather-icon icon="PlusCircleIcon"/>
                                                {{ i18nT(`Add file`) }}
                                            </b-button>
                                            <b-form-file
                                                ref="letterfilePicker"
                                                v-model="apply_letter"
                                                class="hidden"
                                            />
                                            <span v-if="apply_letter || apply_letter_name">
                                                <b-link v-if="apply_letter_url" :href="apply_letter_url">
                                                    {{ apply_letter_name }}
                                                </b-link>
                                                <span v-else> 
                                                    {{ apply_letter_name }}
                                                </span>
                                                <feather-icon
                                                    icon="Trash2Icon" size="16" style="margin-right: 8px;"
                                                    @click="onFileDelete('letter')"/>
                                            </span>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom pt-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                    <h5>
                                            {{ i18nT(`Social links`) }}
                                    </h5>
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                    <div
                                    v-for="(social, index) in addedSocials"
                                        :key="`social`+ index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                        <b-row
                                        
                                            >
                                            <b-col cols="4" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Social network`)"
                                                    label-for="Social network">
                                                    <v-select
                                                        v-model="social.network"
                                                        :options="availableSocials"
                                                        :clearable="false"
                                                        @input="disableSocial"
                                                        label="text"
                                                        :selectable="selected => selected.disabled !== true"
                                                    ></v-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                    cols="8"
                                                    md="8"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Social link`)"
                                                        label-for="Social link"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Social link`)"
                                                            v-model="social.link"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                        </b-row>
                                        <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeSocials(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon" />
                                                    {{i18nT(`Remove`)}}
                                                </b-button>
                                            </div>
                                    </div>
                                    <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addSocials"
                                            >
                                                <feather-icon icon="PlusCircleIcon" />
                                                {{i18nT(`Add more`)}}
                                            </b-button>
                                        </div>
                                    </b-col>

                                </b-row>

                                <b-row>
                                    <div style="flex: 1"/>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        variant="primary"
                                        @click.prevent="validationForm"
                                    >
                                        {{ i18nT(`Save`) }}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

    </div>

</template>

<script>
import {
    BButton,
    BAlert,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    BCardBody,
    BLink,
    // BFormSelect,
    VBModal,
    // BTabs,
    // BTab,
    BFormFile,
    // BInputGroup,
    // BInputGroupText,
    BFormRadio,
    BCardHeader,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {dictToSelectArray} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate'
import {required} from '@validations'
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import AvatarControl from "@/views/components/avatar/AvatarControl";
import DatePicker from '@core/components/DatePicker.vue'
// import useAuth from '@/auth/useAuth';

extend('validPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        if(maz.phoneNumberData){
            return maz.phoneNumberData.isValid ;
        }else {
            return false;
        }
    },
    message: `Invalid phone number`,
});

extend('date_format', {
    validate(value) {
    return {
      required: true,
      valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
    };
  },
  message: `Invalid date.`,
})

extend('validMultiPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        if(maz.phoneNumberData) {
            return maz.phoneNumberData.isValid;
        } else {
            return false;
        }
    },
    message: `Invalid phone number`,
});

export default {
    components: {
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardBody,
        BLink,
        vSelect,
        BFormTextarea,
        // BFormSelect,
        BAlert,
        BBadge,
        BFormCheckbox,
        // BTabs,
        // BTab,
        ValidationProvider,
        ValidationObserver,
        BFormFile,
        // BInputGroup,
        // BInputGroupText,
        BFormRadio,
        BCardHeader,
        BDropdown,
        BDropdownItem,
        AvatarControl,
        DatePicker
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {
        return {
            allCandidateData: {
                FirstName: '',
                LastName: '',
            },
            availableSocials: [
                {value: "Twitter", text: this.i18nT(`Twitter`), disabled: false},
                {value: "Web", text: this.i18nT(`Website`), disabled: false},
                {value: "Behance", text: this.i18nT(`Behance`), disabled: false},
                {value: "Linkedin", text: this.i18nT(`Linkedin`), disabled: false},
                {value: "Dribble", text: this.i18nT(`Dribble`), disabled: false},
                {value: "Github", text: this.i18nT(`Github`), disabled: false},
                {value: "Youtube", text: this.i18nT(`Youtube`), disabled: false},
                {value: "Instagram", text: this.i18nT(`Instagram`), disabled: false},
                {value: "Tiktok", text: this.i18nT(`TikTok`), disabled: false}
            ],
            addedSocials: [{}],
            currentOffer: {},
            UserImage: null,
            file: null,
            fileName: null,
            required,
            jobOffers: [],
            jobOffer: [],
            mazphoneObj: {},
            mazPhones: [],
            firstName: '',
            lastName: '',
            gender: 'male',
            position: '',
            email: '',
            send_invite: false,
            presentation: '',
            skills: [],
            skillOptions: [],
            phones: '',
            phoneReady: 'no',
            apply_cv: null,
            apply_cv_name: null,
            apply_cv_url: '',
            apply_letter: null,
            apply_letter_name: null,
            apply_letter_url: ''
        }
    },
    watch: {
        apply_cv: function (val) {
            if (val) {
                console.log("val: ", val)
                this.apply_cv = val
                this.apply_cv_name = val.Label ? val.Label : (val.name ? val.name : null)
            } else {
                this.apply_cv = null
                this.apply_cv_name = ''
            }
        },
        apply_letter: function (val) {
            if (val) {
                this.apply_letter = val
                this.apply_letter_name = val.name
            } else {
                this.apply_letter = null
                this.apply_letter_name = ''
            }
        },
    },
    created() {
        let self = this;
        Promise.all([
            new Promise((res) => {
                this.$http.get(
                    `candidates/SkillsFilter`
                ).then(({data}) => {
                    this.skillOptions = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `offers/filterOffers`
                ).then(({data}) => {
                    this.jobOffers = dictToSelectArray(data.data)
                    res()
                })
            }),
        ]).then(() => {
            if (router.currentRoute.params.id) {
                this.$http.get(
                    `candidates?id=${router.currentRoute.params.id}`
                ).then(({data}) => {
                    
                    this.allCandidateData = data.data.candidate;
                    this.currentOffer = data.data.current_offer;
                    console.log("Cadnidate data: ", this.allCandidateData)
                    this.phoneReady = 'yes'
                    this.firstName = data.data.candidate.FirstName
                    this.lastName = data.data.candidate.LastName
                    this.gender = data.data.candidate.Gender
                    this.position = data.data.candidate.Position
                    this.email = data.data.candidate.Email
                    this.presentation = data.data.candidate.Presentation
                    this.phones = data.data.candidate.Phone
                    data.data.skills.forEach(skill => {
                        self.skills.push({
                            value: skill.Id,
                            text: skill.Label
                        })
                    });
                    
                    this.availableSocials.forEach(social => {
                        if(this.allCandidateData[social.value]) {
                            if(!this.addedSocials[0].network) {
                                this.addedSocials = []
                            }
                            this.addedSocials.push({
                                network: social,
                                link: this.allCandidateData[social.value]
                            })
                            social.disabled = true
                        }
                    })
                    

                    if (data.data.sourced_application && data.data.sourced_application.CvObj) {
                        this.apply_cv_name = data.data.sourced_application.CvObj.Label;
                        this.apply_cv_url = data.data.sourced_application.CvObj.DownloadUrl
                    }

                    if (data.data.sourced_application && data.data.sourced_application.CoverLetterObj) {
                        this.apply_letter_name = data.data.sourced_application.CoverLetterObj.Label;
                        this.apply_letter_url = data.data.sourced_application.CoverLetterObj.DownloadUrl
                    }

                    if(data.data.offers) {
                        this.jobOffer = Object.values(data.data.offers).map(offer => ({
                            value: offer.Id + '',
                            text: offer.Label
                        }))
                    }
                })
            }
        })
    },
    computed: {
        hasRouteId() {
            return router && router.currentRoute.params.id
        },
        disabled() {
            return router.currentRoute.params.id !== undefined
        }
    },
    methods: {
        addSocials() {
            this.addedSocials.push({})
        },
        disableSocial() {
            this.availableSocials.forEach(soc => soc.disabled = false)

            this.addedSocials.map(social => {
                for (let i = 0; i < this.availableSocials.length; i++) {
                    if(social.network && social.network.value && this.availableSocials[i].value === social.network.value) {
                        this.availableSocials[i].disabled = true
                    }
                } 
            })
        },
        removeSocials(index) {
            this.addedSocials.splice(index, 1)
        },
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },
        updateOnePhoneObj(dataCollected, index){
            this.mazPhones[index] = dataCollected;
            this.mazPhones = [...this.mazPhones]; // to trigger reactivity
        },
        sendInvite() {
            this.$http
                .post('candidates/sendInvite?user_id=' + this.allCandidateData.Id)
                .then(({data}) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(data),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(error),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        onSetNewAvatar(newData) {
            this.UserImage = newData.newUserImage;
            this.UserImageUrl = newData.newImageUrl;
            this.optionsLocal = newData.newProfileData;
        },
        onFilePick: function (type) {
            if (type == 'cv') {
                this.$refs['cvfilePicker'].$refs.input.click()
            } else {
                this.$refs['letterfilePicker'].$refs.input.click()
            }
        },
        onFileDelete: function (type) {
            if (type == 'cv') {
                this.apply_cv = null
                this.apply_cv_name = ''
            } else {
                this.apply_letter = null
                this.apply_letter_name = ''
            }
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    if (router.currentRoute.params.id) {
                        formData.append('id', router.currentRoute.params.id);
                    }

                    formData.append('FirstName', this.allCandidateData.FirstName);
                    formData.append('LastName', this.allCandidateData.LastName);

                    formData.append('SocialSecurity', this.allCandidateData.SocialSecurity);
                    formData.append('Nationality', this.allCandidateData.Nationality);


                    formData.append('BirthDate', this.allCandidateData.BirthDate);
                    formData.append('Gender', this.gender)
                    formData.append('Position', this.position)
                    formData.append('Email', this.email)
                    formData.append('Phone', this.phones)
                    formData.append('Presentation', this.presentation)
                    formData.append('candidate_skills', this.skills.map(skill => skill.value || skill.text || skill).join(","))
                    // formData.append('candidate_offers', this.jobOffer.map(jobOffer => jobOffer.value).join(","))
                    formData.append('send_invite', this.send_invite ? 1 : 0)

                    for(let i = 0; i < this.addedSocials.length; i++) {
                        if(this.addedSocials[i] && this.addedSocials[i].network) {
                            formData.append(this.addedSocials[i].network.value, this.addedSocials[i].link)
                        }
                    }

                    if (this.UserImage) formData.append('user_image', this.UserImage)

                    if (this.apply_cv) {
                        formData.append('apply_cv', this.apply_cv)
                    }

                    if (!this.apply_cv_name) {
                        formData.append('apply_cv_id', 0);
                    }

                    if (this.apply_letter) {
                        formData.append('apply_letter', this.apply_letter)
                    }

                    if (!this.apply_letter_name) {
                        formData.append('apply_letter_id', 0);
                    }

                    this.$http.post(
                        'candidates', formData
                    ).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })

                        if(!router.currentRoute.params.id){
                            router.push({name: 'cvs'})
                        }
                        //router.push({name: 'applications'})
                    })
                        .catch((ex) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'InfoIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        },
        onSave() {
            console.log('Saved!!!')
        },
        onDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this entry?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT('Confirm'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    console.log('Deleted!')
                }
            })
        }
    }
}
</script>

<style lang="scss">
.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}
</style>
